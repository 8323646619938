<template>
  <div>
    <Title>
      Клиенты
      <plus-button @click.native="create = true">Создать</plus-button>
    </Title>

    <create v-if="create" @close="create = false"/>
    <edit v-if="edit" :row="row" @close="edit = false"/>
    <delete v-if="del" :row="row" @close="del = false"/>

    <vue-good-table
        ref="vueTable"
        :columns="columns"
        :isLoading.sync="isLoading"
        :pagination-options="paginationOptions"
        :rows="rows"
        :totalRows="totalRecords"
        mode="remote"
        styleClass="primary-table"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
    >
      <template slot="column-filter" slot-scope="{column, updateFilters }">

        <the-mask
            v-if="column.filterOptions.inputType === 'phone'"
            v-model="data.filter.phone"
            :placeholder="column.filterOptions.placeholder"
            class="vgt-input"
            mask="+7 (###) ### ##-##"
            type="text"
            @input="updateFilters(column, data.filter.phone)"
        />

        <DatePicker
            v-else-if="column.filterOptions.inputType === 'date'"
            v-model="data.filter.birthdate"
            :isFilter="true"
            :placeholder="column.filterOptions.placeholder"
            @input="updateFilters(column, data.filter.birthdate)"
        />

      </template>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'full_name'" class="relative">
          {{ getFullName(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'address'" class="relative">
          {{ getAddress(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'documents'" class="relative">
          {{ getPassport(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'snils'" class="relative">
          {{ getSnils(props.row) }}
          <a-dropdown class="primary-table__actions-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay" class="primary-table__dropdown-menu">
              <a-menu-item>
                <a @click="onEdit(props.row)">
                  <a-icon type="edit"/>
                  Изменить
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="onDelete(props.row)">
                  <a-icon type="delete"/>
                  Удалить
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <Pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
      <div slot="emptystate" class="text-center">
        Нет данных
      </div>
      <template slot="loadingContent">
        <span class="vgt-loading__content">
            Загрузка...
        </span>
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import Vue from "vue";
import moment from 'moment';
import {vueTable} from "@/mixins/vue-table";
import Title from "@/components/ui/Title";
import PlusButton from "@/components/ui/PlusButton";
import DatePicker from "@/components/ui/DatePicker";
import {getAll} from "@/api/client/getAll"
import ADropdown from "ant-design-vue/lib/dropdown";
import AMenu from "ant-design-vue/lib/menu";
import AIcon from "ant-design-vue/lib/icon";
import Create from "./modal/Create";
import Edit from "./modal/Edit";
import Delete from "./modal/Delete";
import Pagination from '@/components/ui/Pagination';

Vue.use(AMenu);

export default {
  mixins: [vueTable],
  data() {
    return {
      getAll,
      data: {
        create: false,
        edit: false,
        del: false,
        filter: {
          phone: null,
          birthdate: null,
          snils: null,
          passport: null,
        }
      },
      moment,
    }
  },
  components: {
    ADropdown,
    AMenu,
    AIcon,
    Title,
    PlusButton,
    DatePicker,
    Create,
    Edit,
    Delete,
    Pagination
  },
  computed: {
    columns() {
      return [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
          width: '7rem',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ID',
            trigger: 'enter',
            inputType: 'text',
          },
        },
        {
          field: 'full_name',
          label: 'ФИО',
          sortable: true,
          width: '13%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ФИО',
            trigger: 'enter',
            inputType: 'text',
          },
        },
        {
          field: 'gender',
          label: 'Пол',
          sortable: true,
          width: '10%',
          tdClass: 'fold',
          formatFn: obj => {
            return obj.label;
          },
          filterOptions: {
            enabled: true,
            placeholder: 'Пол',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'male', text: 'Мужской'},
              {value: 'female', text: 'Женский'},
            ],
            inputType: 'select',
          },
        },
        {
          field: 'birthdate',
          label: 'Дата рожд.',
          sortable: true,
          width: '170px',
          tdClass: 'fold',
          dateInputFormat: 'dd.MM.yyyy',
          dateOutputFormat: 'dd.MM.yyyy',
          filterOptions: {
            enabled: true,
            placeholder: 'Дата',
            trigger: 'enter',
            inputType: 'date',
          },
          formatFn: (value) => {
            return this.moment(value).format('DD.MM.YYYY');
          }
        },
        {
          field: 'phone',
          label: 'Телефон',
          sortable: true,
          width: '15%',
          tdClass: 'fold',
          formatFn: value => {
            return this.$options.filters.phoneNumber(value)
          },
          filterOptions: {
            enabled: true,
            placeholder: 'Телефон',
            trigger: 'enter',
            inputType: 'phone',
          },
        },
        {
          field: 'address',
          label: 'Адрес',
          sortable: true,
          width: '16%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Адрес',
            trigger: 'enter',
            inputType: 'text',
          },
        },
        {
          field: 'documents',
          label: 'Документ',
          sortable: true,
          width: '14%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            customFilter: true,
            placeholder: 'Серия и номер',
            trigger: 'enter',
            inputType: 'text',
          },
        },
        {
          field: 'snils',
          label: 'СНИЛС',
          sortable: true,
          width: '15%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Номер',
            trigger: 'enter',
            inputType: 'text',
          },
        },
      ]
    }
  },
  methods: {
    getFullName(row) {
      let ru = '';
      let en = '';

      if (row.attributes.ru.surname) {
        ru = (row.attributes.ru.surname || '') + ' ' +
            (row.attributes.ru.name || '') + ' ' +
            (row.attributes.ru.patronymic || '');
      }

      if (row.attributes.en.surname) {
        en = (row.attributes.en.surname || '') + ' ' +
            (row.attributes.en.name || '') + ' ' +
            (row.attributes.en.patronymic || '');
      }

      return ru ? ru : en;
    },
    getAddress(row) {
      return row.attributes.ru.address ? row.attributes.ru.address : row.attributes.en.address
    },
    getPassport(row) {
      const passport = this.getDocument(row, 'passport');

      if (passport) {
        return passport.series + ' ' + passport.number
      }

      return '';
    },
    getSnils(row) {
      const snils = this.getDocument(row, 'snils');

      if (snils) {
        return this.$options.filters.snils(snils.number);
      }

      return '';
    },
    getDocument(row, type) {
      return row.documents.find(obj => {
        if (obj.type.value === type) {
          return true;
        }
      });
    },
  },
}
</script>