<template>
  <div class="modal">
    <modal @close="close">
      <template v-slot:title>
        Создание клиента
      </template>
      <template v-slot:body>

        <div class="scroll-wrapper">
          <div class="modal__form-row">
            <div class="modal__form-col">
              <v-input
                  v-model="form.surname_ru"
                  :error="errors.surname_ru"
                  autofocus
                  placeholder="Фамилия (RU)"
                  required
              />
            </div>
            <div class="modal__form-col">
              <v-input
                  v-model="form.name_ru"
                  :error="errors.name_ru"
                  placeholder="Имя (RU)"
                  required
              />
            </div>
            <div class="modal__form-col">
              <v-input
                  v-model="form.patronymic_ru"
                  :error="errors.patronymic_ru"
                  placeholder="Отчество (RU)"
                  required
              />
            </div>
          </div>
          <div class="modal__form-row">
            <div class="modal__form-col">
              <DatePicker v-model="form.birthdate" :error="errors.birthdate" placeholder="Дата рождения"
                          required/>
            </div>
            <div class="modal__form-col">
              <v-input
                  v-model="form.phone"
                  :error="errors.phone"
                  placeholder="Телефон"
                  required
              />
            </div>
            <div class="modal__form-col">
              <v-input
                  v-model="form.email"
                  :error="errors.email"
                  placeholder="E-mail"
                  required
              />
            </div>
          </div>
          <div class="modal__form-row">
            <div class="modal__form-col">
              <v-input
                  v-model="form.address_ru"
                  :error="errors.address_ru"
                  placeholder="Адрес (RU)"
                  required
              />
            </div>
            <div class="modal__form-col">
              <div class="grid grid-cols-2 gap-4">
                <div style="display: flex; margin-top: 14px; margin-left: 14px">
                  <VRadio v-model="form.gender" val="male">Мужской</VRadio>
                  <VRadio v-model="form.gender" val="female">Женский</VRadio>
                </div>
              </div>
            </div>
          </div>
  
          <h2 class="document">
            Документы (не обязательно)
            <a-icon
              type="plus"
              class="document-add"
              @click="addDocument"
            />
          </h2>
  
          <div
            class="modal__form-row"
            v-for="(document, index) in documents"
            :key="index"
          >
            <div class="modal__form-col" style="width: 32%;">
              <v-select
                v-model="document.type"
                :options="filteredDocs(index)"
                :error="errors[`${document.type}`]"
                placeholder="Тип документа"
                required
              />
            </div>
            <div class="modal__form-col" style="width: 32%;" v-if="document.type !== 'snils'">
              <v-input
                v-model="document.series"
                :error="errors[`${document.type}_series`]"
                placeholder="Серия документа"
                required
              />
            </div>
            <div class="modal__form-col" style="width: 32%;">
              <v-input
                v-model="document.number"
                :error="errors[`${document.type}_number`]"
                placeholder="Номер документа"
                required
              />
            </div>
            <div class="modal__form-col" style="width: 4%;">
              <a-icon
                type="minus"
                class="document-del"
                @click="delDocument(index)"
              />
            </div>
          </div>

          <h2>EN (не обязательно)</h2>
          <div class="modal__form-row">
            <div class="modal__form-col">
              <v-input
                  v-model="form.surname_en"
                  :error="errors.surname_en"
                  placeholder="Фамилия (EN)"
              />
            </div>
            <div class="modal__form-col">
              <v-input
                  v-model="form.name_en"
                  :error="errors.name_en"
                  placeholder="Имя (EN)"
              />
            </div>
            <div class="modal__form-col">
              <v-input
                  v-model="form.patronymic_en"
                  :error="errors.patronymic_en"
                  placeholder="Отчество (EN)"
              />
            </div>
          </div>
          <div class="modal__form-row">
            <div class="modal__form-col">
              <v-input
                  v-model="form.address_en"
                  :error="errors.address_en"
                  placeholder="Адрес (EN)"
                  required
              />
            </div>
            <div class="modal__form-col"></div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <v-button class="gray" @click.native="close">
          Отмена
        </v-button>
        <v-button @click.native="save">
          Сохранить
        </v-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {create as createClient} from "@/api/client/create";
import Modal from "@/components/ui/Modal";
import VButton from "@/components/ui/VButton";
import VInput from "@/components/ui/VInput";
import DatePicker from "@/components/ui/DatePicker"
import VRadio from "@/components/ui/VRadio";
import VSelect from "@/components/ui/VSelect";
import AIcon from "ant-design-vue/lib/icon";
import _ from "lodash";

export default {
  data() {
    return {
      form: {
        surname_ru: null,
        name_ru: null,
        patronymic_ru: null,

        surname_en: undefined,
        name_en: undefined,
        patronymic_en: undefined,
        address_en: undefined,

        gender: 'male',
        address_ru: null,
        birthdate: null,
        email: null,
        phone: null,
        snils: null,
      },
      docs: [
        {value: 'snils', text: 'СНИЛС'},
        {value: 'passport', text: 'Паспорт гражданина РФ'},
        {value: 'international_passport', text: 'Заграничный паспорт'},
        {value: 'birth_certificate', text: 'Свидетельство о рождении'},
        {value: 'residence_permit', text: 'Вид на жительство'},
        {value: 'foreign_citizen_passport', text: 'Паспорт иностранного гражданина'},
        {value: 'other', text: 'Иное'},
      ],
      errors: {},
      documents: [
        {
          type: 'passport',
          series: null,
          number: null,
        }
      ],
    }
  },
  components: {
    Modal,
    VButton,
    VInput,
    VRadio,
    DatePicker,
    VSelect,
    AIcon
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.errors = {};

      //передаем выбранные серию / номер дока
      this.documents.forEach(document => {
        if (document.type === 'snils') {
          this.form.snils = document.number
        } else {
          this.form[`${document.type}_series`] = document.series
          this.form[`${document.type}_number`] = document.number
        }
      })

      let form = _.omitBy(_.cloneDeep(this.form), _.isNil);

      createClient(form)
          .then(() => {
            this.$parent.loadItems();
            this.getNotify('success', 'Создано');
            this.close();
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (
                Object.prototype.hasOwnProperty.call(this.errors, 'snils') &&
                this.documents.find(document => document.type === 'snils') === undefined
              ) {
                this.documents.push({
                  type: 'snils',
                  series: null,
                  number: null,
                })
              }
              this.getNotify('error','Ошибка сохранения')
            }
          })
    },
    addDocument() {
      this.documents.push({
        type: null,
        series: null,
        number: null,
      })
    },
    delDocument(index) {
      this.documents.splice(index, 1);
    },
    filteredDocs(index) {
      return this.docs.filter(doc => {
        let bool = true;
      
        this.documents.forEach((document, key) => {
          if (
            index !== key &&
            doc.value === document.type
          ) {
            bool = false;
          }
        })
      
        return bool;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.modal::v-deep {
  .modal__wrapper {
    max-width: 836px;
    width: 100%;
  }

  h2 {
    margin: 12px 0;
  }

  .scroll-wrapper {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 10px;
    margin-bottom: 20px;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0059A3;
      border-radius: 3px;
    }
  }
  
  .document {
    display: flex;
    align-items: center;
    
    &-add {
      background-color: #0059A3;
      border-radius: 99px;
      padding: 5px;
      color: white;
      margin-left: 10px;
      cursor: pointer;
      
      &:hover {
        background-color: #046dc6;
      }
    }
    
    &-del {
      background-color: #0059A3;
      border-radius: 99px;
      padding: 5px;
      color: white;
      cursor: pointer;
      margin-top: 12px;
      
      &:hover {
        background-color: #046dc6;
      }
    }
  }
}
</style>
