<template>
  <div class="datepicker" :class="{ 'datepicker--invalid': error, 'datepicker--filter': isFilter, }">
    <a-date-picker
      :required="required"
      :locale="locale"
      :placeholder="placeholder"
      :value="value ? value : null"
      :format="format"
      :showTime="showTime ? {format: 'HH:mm'} : null"
      class="datepicker__datepicker"
      @change="onChange"
    />
    <div class="datepicker__error" v-if="error">{{ error[0] }}</div>
  </div>
</template>

<script>
import ADatePicker from 'ant-design-vue/lib/date-picker'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';

export default {
  name: "DatePicker",
  props: {
    required: Boolean,
    value: [String, Object],
    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    error: Array,
    isFilter: Boolean,
  },
  data() {
    return {
      locale,
      // val: null,
    }
  },
  created() {
    // this.val = this.value
  },
  components: {
    ADatePicker
  },
  methods: {
    onChange(date) {
      this.$emit('input', date)
    }
  },
}
</script>

<style lang="scss" scoped>
.datepicker {
  margin-bottom: 26px;

  .datepicker__datepicker::v-deep {
    width: 100%;

    .ant-calendar-picker {
      width: 100%;
      margin-bottom: 8px;
    }

    ::placeholder {
      color: #9ea3a7!important;
    }

    .ant-calendar-picker-input.ant-input {
      padding: 9px 22px;
      font-size: 15px;
      line-height: 18px;
      color: #1D293F;
      height: 50px;
    }
  }

  .datepicker__error {
    display: none;
    opacity: 0;
    font-size: 13px;
    line-height: 17px;
    color: #F2323F;
    transition: all 200ms ease;
  }
}

.datepicker--invalid {
  .datepicker__datepicker::v-deep {
    .ant-calendar-picker-input.ant-input {
      background-color: #FFEAEA;
      border-color: #F2323F;
    }
  }

  .datepicker__error {
    display: block;
    opacity: 1;
    margin-top: 8px;
  }
}

.datepicker--filter {
  margin-bottom: 0;

  .datepicker__datepicker::v-deep {
    width: 100%;
    margin-left: 0;

    .ant-calendar-picker-input.ant-input {
      padding: 6px 16px;
      background-color: #F4F7FC;
      border: 1px solid #F4F7FC;
      border-radius: 4px;
      color: #9C9EA3;
      height: 40px;

      &::placeholder {
        color: #9C9EA3!important;
      }
    }
  }
}
</style>