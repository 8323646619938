<template>
  <div class="modal">
    <modal @close="close">
      <template v-slot:title>
        Удаление клиента
      </template>
      <template v-slot:body>

        <div class="modal__form-text">
          Вы уверены что хотите удалить клиента?
        </div>

      </template>
      <template v-slot:footer>
        <v-button class="gray" @click.native="close">
          Отмена
        </v-button>
        <v-button @click.native="submit">
          Удалить
        </v-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {destroy} from "@/api/client/destroy";
import Modal from "@/components/ui/Modal";
import VButton from "@/components/ui/VButton";

export default {
  data() {
    return {
      destroy,
    }
  },
  components: {
    Modal,
    VButton,
  },

  props: {
    row: Object,
  },
  methods: {
    close() {
      this.$emit('close');
    },

    submit() {
      destroy(this.row.id)
          .then(() => {
            this.$parent.loadItems();
            this.getNotify('success', 'Удалено');
            this.close();
          })
          .catch(error => {
            if (error.response.status === 422) {
              if ('id' in error.response.data.errors) {
                this.getNotify('error', error.response.data.errors.id[0]);
              }
              this.errors = error.response.data.errors;
            }
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.modal__form-text {
  margin-bottom: 32px;
  font-size: 16px;
}
</style>