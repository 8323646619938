<template>
  <label class="radio">
    <slot></slot>
    <input
      v-model="value"
      :value="val"
      type="radio"
      @input="$emit('input', $event.target.value)"
    />
    <span class="radio__check"></span>
  </label>
</template>

<script>
export default {
  name: "VRadio",
  props: {
    value: String,
    val: String,
  }
}
</script>

<style lang="scss" scoped>
.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  line-height: 18px;
  color: #1D293F;
  cursor: pointer;
  margin-right: 30px;

  input {
    position: absolute;
    opacity: 0;

    &:checked ~ .radio__check {
      background-color: #00ADFF;
      border-color: #00ADFF;
    }

    &:checked ~ .radio__check:after {
      opacity: 1;
    }
  }

  .radio__check {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #D5D9E1;
    border-radius: 50%;
    transition: all 200ms ease-in-out;

    &:after {
      content: "";
      position: absolute;
      opacity: 1;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 6px;
      border-radius: 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      transform: translate(-50%, -50%);
      transition: opacity 200ms ease-in-out;
    }
  }
}
</style>